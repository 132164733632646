<template>
  <section class="w-progress">
    <div class="w-progress__info">
      {{
        $t("widget.progress.info", {
          text: progress.text ?? this.$t('widget.progress.defaultText'),
          current: progress.current,
          total: progress.total
        })
      }}
    </div>
    <div class="w-progress__container">
      <v-progress-linear
        :value="progressPercents"
      />
    </div>
  </section>
</template>

<script>
import VProgressLinear from '@components/base/VProgressLinear'

export default {
  name: 'WProgress',

  components: {
    VProgressLinear
  },

  props: {
    progress: {
      type: Object,
      default: () => ({
        total: 0,
        current: 0
      })
    }
  },

  computed: {
    progressPercents () {
      return (this.progress.current - 1) / this.progress.total * 100
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@assets/styles/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.w-progress {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &__info {
    //width: 100%;
    font-weight: 600;
    margin-bottom: .5rem;
    white-space: nowrap;

    @include media-breakpoint-down(lg) {
      width: auto;
      margin: 0 1rem 0 0;
    }
  }

  &__container {
    flex: 1 0 auto;
    width: 100%;
    background-color: var(--color-background-4);

    @include media-breakpoint-down(lg) {
      width: auto;
    }
  }
}
</style>
